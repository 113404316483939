import React from 'react';
import * as Yup from 'yup';

import { Fields, DynamicFormField } from '@/typings/model';

import { fieldsVmSchema } from './schema';
import { PrepareVmValues, ViewModal } from './types';

type UseViewModalHook = (
  fields: DynamicFormField[]
) => {
  initialValues: ViewModal;
  validationSchema: Yup.ObjectSchema<ViewModal>;
  prepare: (vm: ViewModal) => PrepareVmValues;
};

const makeViewModal: UseViewModalHook = (fields) => {
  const fieldsVm: ViewModal = {};
  const fieldsVmDef: Yup.ObjectSchemaDefinition<ViewModal> = {};

  fields?.forEach((field, index) => {
    if (field.type === Fields.Checkbox) {
      fieldsVm[index] = { type: field.type, value: false };
    }

    if (field.type === Fields.Textarea) {
      fieldsVm[index] = { type: field.type, value: '' };
    }

    if (field.type === Fields.Email) {
      fieldsVm[index] = { type: field.type, value: '' };
    }

    if (field.type === Fields.Input) {
      fieldsVm[index] = { type: field.type, value: '' };
    }

    if (field.type === Fields.Link) {
      fieldsVm[index] = { type: field.type, value: '' };
    }

    fieldsVmDef[index] = fieldsVmSchema({
      label: field.label ?? '',
      optional: field.optional ?? false,
    });
  });

  const schema = Yup.object(fieldsVmDef).required();

  return {
    initialValues: schema.cast(fieldsVm),
    validationSchema: schema,
    prepare: (vm: ViewModal) => Object.values(vm).map((field) => field.value),
  };
};

export const useViewModal: UseViewModalHook = (fields) =>
  React.useMemo(() => makeViewModal(fields), [fields]);
