import React from 'react';
import styled, { css } from 'styled-components';

import TextInputFormik from '@/components/TextInput/TextInput.formik';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

type InputProps = {
  name: string;
  label: string;
};

export const Input: React.FC<InputProps> = ({ name, label }) => {
  return <Component name={name} placeholder={label} />;
};

const Component = styled(TextInputFormik)`
  position: relative;
  margin-top: 59px;

  ${media.tabletSmallOnly(css`
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    margin-top: 60px;
  `)}
  
  input {
    padding: 0;
    width: 100%;
    font-weight: 400;
    font-size: 21px;
    height: 35px;
    line-height: 100%;
    border-bottom: 1.77px solid ${colors.white};
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    background: transparent;
    color: ${colors.white};

    &::placeholder {
      color: ${colors.white};
    }

    &:focus {
      color: ${colors.white} !important;
      background: transparent !important;
      box-shadow: none !important;
      border-color: ${colors.white} !important;
    }
  }
`;
