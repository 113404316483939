import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

export const FormContent = styled.fieldset`
  border: none;
  position: relative;
  padding: 0;
  width: 100%;
  flex: 1 1 auto;
`;

export const FormInner = styled.div<{ isSentSuccess: boolean }>`
  margin-top: -57px;
  transition: all 0.15s ease-in-out;

  ${media.tabletSmallOnly(css`
    margin-top: -50px;
  `)}

  ${media.mobile(css`
    margin-top: -60px;
  `)}

  ${(props) =>
    props.isSentSuccess
      ? css`
          opacity: 0;
        `
      : css`
          opacity: 1;
        `};
`;
