import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

export const Wrapper = styled.div`
  position: relative;
`;

export const Inner = styled.span`
  font-size: 21px;
  line-height: 100%;
`;

export const Label = styled.span`
  padding-left: 30px;
  position: relative;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: ${colors.white};

  ${media.mobile(css`
    font-size: 14px;
  `)}

  a {
    z-index: 2;
    position: relative;
    text-decoration: none;
    color: ${colors.red};
  }

  svg {
    position: absolute;
    opacity: 0;
    transition: 0.3s all ease;
    left: 3px;
    top: 2px;

    width: 16px;
    height: 16px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 25%;
    border: 1px solid ${colors.white};
    transition: 0.3s all ease;
  }
`;

export const Component = styled.label<{ disabled?: boolean }>`
  position: relative;
  display: inline-flex;
  cursor: pointer;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    z-index: 1;
    cursor: inherit;

    &:checked + span {
      &:before {
        background: ${colors.red};
        border-color: ${colors.red};
      }
      svg {
        opacity: 1;
      }
    }
  }
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;

export const ErrorMessage = styled.span`
  position: absolute;
  left: 0;
  bottom: -18px;
  font-size: 11px;
  line-height: 15px;
  color: ${colors.red};
`;
