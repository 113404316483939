import React from 'react';
import { useField } from 'formik';

import { ReactComponent as CheckboxTick } from '@/assets/svg/checkbox-tick.svg';

import { Component, ErrorMessage, Inner, Label, Wrapper } from './styles';

type Props = {
  name: string;
  label: string;
  className?: string;
};

export const CheckboxFormik = ({ name, label, className }: Props) => {
  const [field, meta] = useField(name);

  return (
    <Wrapper>
      <Component className={className}>
        <input id={name} type="checkbox" {...field} />
        <Label>
          <CheckboxTick />
          <Inner dangerouslySetInnerHTML={{ __html: label }} />
        </Label>
      </Component>
      {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </Wrapper>
  );
};
