import React from 'react';
import { useFormikContext } from 'formik';
import styled, { css } from 'styled-components';

import { ViewModal } from '@/components/forms/DynamicContactsForm/types';
import Spinner from '@/components/Spinner';
import Button from '@/components/Button';

export const Controls: React.FC = () => {
  const { isSubmitting } = useFormikContext<ViewModal>();

  return (
    <Component>
      <SendButton
        variants={['large', 'red', 'w100']}
        type="submit"
        isSubmitting={isSubmitting}
      >
        {!isSubmitting ? 'Отправить' : <Spinner color="dark" absolute={true} />}
      </SendButton>
    </Component>
  );
};

const Component = styled.div`
  margin-top: 34px;
  text-align: center;
`;

const SendButton = styled(Button)<{ isSubmitting: boolean }>`
  position: relative;
  min-height: 70px;
  ${(props) =>
    props.isSubmitting
      ? css`
          border: none;
        `
      : css``}
`;
