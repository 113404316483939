import React from 'react';

import { Fields, DynamicFormField } from '@/typings/model';

import { DynamicFieldProps } from './types';
import { Input, Textarea, Checkbox } from './fields';

const getField = (name: string, field: DynamicFormField): JSX.Element => {
  switch (field.type) {
    case Fields.Input:
      return <Input name={name} label={field.label ?? ''} />;

    case Fields.Email:
      return <Input name={name} label={field.label ?? ''} />;

    case Fields.Textarea:
      return <Textarea name={name} label={field.label ?? ''} />;

    case Fields.Link:
      return <Input name={name} label={field.label ?? ''} />;

    case Fields.Checkbox:
      return <Checkbox name={name} label={field.label ?? ''} />;

    default:
      return <div>default</div>;
  }
};

export const DynamicField: React.FC<DynamicFieldProps> = ({ name, field }) => {
  return getField(name, field);
};
