import * as Yup from 'yup';

import {
  CheckboxVM,
  EmailVM,
  FieldsVM,
  InputVM,
  LinkVM,
  TextareaVM,
} from '@/components/forms/DynamicContactsForm/types';
import { Fields } from '@/typings/model';

export const fieldsVmSchema = ({
  label,
  optional,
}: {
  label: string;
  optional: boolean;
}) =>
  Yup.lazy<FieldsVM>((fields) => {
    switch (fields.type) {
      case Fields.Checkbox:
        return Yup.object<CheckboxVM>({
          type: Yup.mixed<Fields.Checkbox>().oneOf([Fields.Checkbox]),
          value: optional
            ? Yup.boolean().label(label)
            : Yup.boolean().label(label).oneOf([true], 'Поставьте галочку'),
        }).required();

      case Fields.Textarea:
        return Yup.object<TextareaVM>({
          type: Yup.mixed<Fields.Textarea>().oneOf([Fields.Textarea]),
          value: optional
            ? Yup.string().ensure().label(label)
            : Yup.string().ensure().label(label).required('Заполните поле'),
        }).required();

      case Fields.Email:
        return Yup.object<EmailVM>({
          type: Yup.mixed<Fields.Email>().oneOf([Fields.Email]),
          value: optional
            ? Yup.string()
                .ensure()
                .label(label)
                .email('Введите email корректно')
            : Yup.string()
                .ensure()
                .label(label)
                .email('Введите email корректно')
                .required('Заполните поле'),
        }).required();

      case Fields.Input:
        return Yup.object<InputVM>({
          type: Yup.mixed<Fields.Input>().oneOf([Fields.Input]),
          value: optional
            ? Yup.string().ensure().label(label)
            : Yup.string().ensure().label(label).required('Заполните поле'),
        }).required();

      case Fields.Link:
        return Yup.object<LinkVM>({
          type: Yup.mixed<Fields.Link>().oneOf([Fields.Link]),
          value: optional
            ? Yup.string()
                .url('Введите корректную ссылку')
                .ensure()
                .label(label)
            : Yup.string()
                .url('Введите корректную ссылку')
                .ensure()
                .label(label)
                .required('Заполните поле'),
        }).required();
    }
  });
