import React from 'react';
import styled, { css } from 'styled-components';

import { CheckboxFormik } from '@/components/Checkbox/Checkbox.formik';
import { media } from '@/utils/mixin';

type CheckboxProps = {
  name: string;
  label: string;
};

export const Checkbox: React.FC<CheckboxProps> = ({ name, label }) => {
  return (
    <Component>
      <CheckboxFormik name={name} label={label} />
    </Component>
  );
};

const Component = styled.div`
  margin-top: 59px;

  ${media.tabletSmallOnly(css`
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    margin-top: 60px;
  `)}
`;
