import React from 'react';
import styled, { css } from 'styled-components';

import { TextAreaFormik } from '@/components/TextArea';
import { media } from '@/utils/mixin';

type TextareaProps = {
  name: string;
  label: string;
};

export const Textarea: React.FC<TextareaProps> = ({ name, label }) => {
  return (
    <TextAreaContainer>
      <TextAreaFormik
        className="textarea-field"
        placeholder={label}
        name={name}
        autoHeight
      />
    </TextAreaContainer>
  );
};

const TextAreaContainer = styled.div`
  position: relative;
  margin-top: 59px;

  .textarea-field {
    line-height: 0;
  }

  ${media.mobile(css`
    margin-top: 60px;
  `)}
`;
