import React, { useState } from 'react';
import { noop } from 'lodash';
import { Form, Formik, FormikHelpers } from 'formik';

import MessageSuccess from '@/components/modals/MessageSuccess';

import { DynamicContactsFormProps, ViewModal } from './types';
import { useViewModal } from './useViewModal';
import { DynamicField } from './DynamicField';
import { Controls } from './Controls';
import { FormContent, FormInner } from './styles';

export const DynamicContactsForm: React.FC<DynamicContactsFormProps> = ({
  fields,
  submitAsync = noop,
}) => {
  const [isSentSuccess, setSentSuccess] = useState(false);
  const { initialValues, validationSchema, prepare } = useViewModal(fields);

  const onSubmit = async (
    vm: ViewModal,
    { setSubmitting, resetForm }: FormikHelpers<ViewModal>
  ) => {
    setSubmitting(true);

    try {
      await submitAsync?.(prepare(vm));
      resetForm();
      setSentSuccess(true);
      setTimeout(() => {
        setSentSuccess(false);
      }, 2500);
    } catch (error) {
      console.error(error);
    }

    setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      <Form autoComplete="off" noValidate>
        <FormContent>
          <MessageSuccess isSentSuccess={isSentSuccess} />
          <FormInner isSentSuccess={isSentSuccess}>
            {fields?.map((field, index) => (
              <DynamicField key={index} name={`${index}.value`} field={field} />
            ))}

            <Controls />
          </FormInner>
        </FormContent>
      </Form>
    </Formik>
  );
};
